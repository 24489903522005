import { lazy, Suspense } from 'react'
import { BrowserRouter, Route} from "react-router-dom";
import { publicRoutes,privateRoutes } from "../../services/routes";
import { NotFound } from "../../pages/404";
import { Forbidden } from "../../pages/forbidden";
import Waiting from "../../pages/parts/waiting";
// import { UserContext } from '../../services/context/UserContext'
// import { versions } from '../../main/constants';
import tool from '../../services/tools';

// ARCHIVOS PARA RUTAS DE ADMIN O DASHBOARDS
const Login = lazy(() => import(/* webpackChunkName: "login" */ "../../pages/login"));
const Index = lazy(() => import(/* webpackChunkName: "/" */ "../../pages/default"));
const Dashboard = lazy(() => import(/* webpackChunkName: "/dash" */ "../../pages/dashboard"));
const DashboardTeacher = lazy(() => import(/* webpackChunkName: "/dash" */ "../../pages/dashboardTeacher"));
const DashboardStudent = lazy(() => import(/* webpackChunkName: "/dash" */ "../../pages/dashboardStudent"));
const DashboardAdmon = lazy(() => import(/* webpackChunkName: "/dash" */ "../../pages/dashboardAdmon"));
const MenuList = lazy(() => import(/* webpackChunkName: "/menu" */ "../../pages/menu/menuList"));
const MenuAdd = lazy(() => import(/* webpackChunkName: "/menu" */ "../../pages/menu/menuAdd"));

// ARCHIVOS PARA RUTAS DE DOCENTES
    const AsignacionesView = lazy(() => import(/* webpackChunkName: "asignaciones" */ "../../pages/login"));
    const AsignacionesCreate = lazy(() => import(/* webpackChunkName: "asignaciones" */ "../../pages/login"));
    const HorariodeClases = lazy(() => import(/* webpackChunkName: "asignaciones" */ "../../pages/login"));
    const HorariodeClasesOpen = lazy(() => import(/* webpackChunkName: "asignaciones" */ "../../pages/login"));

    const AsistenciaTeacher = lazy(() => import(/* webpackChunkName: "asistencia" */ "../../pages/asistencias/AsistenciasMarcar"));
    const AsistenciaTeacherListar = lazy(() => import(/* webpackChunkName: "asistencia" */ "../../pages/asistencias/AsistenciasListar"));
    const AsistenciaListar = lazy(() => import(/* webpackChunkName: "asistencia" */ "../../pages/asistencias/AsistenciasListarSchool"));
    const AsistenciaSeguimiento = lazy(() => import(/* webpackChunkName: "asistencia" */ "../../pages/asistencias/AsistenciasSeguimiento"));


    
    const AusentismoGeneral = lazy(() => import(/* webpackChunkName: "ausentismo" */ "../../pages/login"));
    
    const CitacionCreate = lazy(() => import(/* webpackChunkName: "citaciones" */ "../../pages/login"));
    const CitacionList = lazy(() => import(/* webpackChunkName: "citaciones" */ "../../pages/login"));
    const CitacionView = lazy(() => import(/* webpackChunkName: "citaciones" */ "../../pages/login"));
    
    const CalendarCreate = lazy(() => import(/* webpackChunkName: "calendar" */ "../../pages/login"));
    const CalendarView = lazy(() => import(/* webpackChunkName: "calendar" */ "../../pages/login"));
    const CalendarViewOpen = lazy(() => import(/* webpackChunkName: "calendar" */ "../../pages/login"));

    
    const StudentsView = lazy(() => import(/* webpackChunkName: "students" */ "../../pages/login"));
    const StudentsCreate = lazy(() => import(/* webpackChunkName: "students" */ "../../pages/login"));
    const StudentsList = lazy(() => import(/* webpackChunkName: "students" */ "../../pages/login"));

    const ExcusasView = lazy(() => import(/* webpackChunkName: "excusas" */ "../../pages/excusas/excusasView"));
    const ExcusasViewOpen = lazy(() => import(/* webpackChunkName: "excusas" */ "../../pages/excusas/excusasViewOpen"));
    const ExcusasCreate = lazy(() => import(/* webpackChunkName: "excusas" */ "../../pages/login"));
    const ExcusasList = lazy(() => import(/* webpackChunkName: "excusas" */ "../../pages/excusas/excusasList"));

    const ObservadorView = lazy(() => import(/* webpackChunkName: "observador" */ "../../pages/login"));
    const ObservadorCreate = lazy(() => import(/* webpackChunkName: "observador" */ "../../pages/login"));
    const ObservadorList = lazy(() => import(/* webpackChunkName: "observador" */ "../../pages/login"));

    const ComunicacionView = lazy(() => import(/* webpackChunkName: "alerts" */ "../../pages/comunicados/comunicadosView"));
    const ComunicacionViewOpen = lazy(() => import(/* webpackChunkName: "alerts" */ "../../pages/comunicados/comunicadosViewOpen"));
    const ComunicacionCreate = lazy(() => import(/* webpackChunkName: "alerts" */ "../../pages/comunicados/comunicadosAdd"));
    const ComunicacionList = lazy(() => import(/* webpackChunkName: "alerts" */ "../../pages/comunicados/comunicadosList"));


    const ExamenViewResolver = lazy(() => import(/* webpackChunkName: "examen" */ "../../pages/login"));
    const ExamenViewCalificar = lazy(() => import(/* webpackChunkName: "examen" */ "../../pages/login"));
    const ExamenView = lazy(() => import(/* webpackChunkName: "examen" */ "../../pages/login"));
    const ExamenViewSede = lazy(() => import(/* webpackChunkName: "examen" */ "../../pages/login"));
    const ExamenCreate = lazy(() => import(/* webpackChunkName: "examen" */ "../../pages/login"));
    const ExamenList = lazy(() => import(/* webpackChunkName: "examen" */ "../../pages/login"));
    //hacen falta las rutas para las preguntas y para las opciones de pregunta

    const TareaViewResolver = lazy(() => import(/* webpackChunkName: "examen" */ "../../pages/login"));
    const TareaViewCalificar = lazy(() => import(/* webpackChunkName: "examen" */ "../../pages/login"));
    const TareaView = lazy(() => import(/* webpackChunkName: "examen" */ "../../pages/login"));
    const TareaViewSede = lazy(() => import(/* webpackChunkName: "examen" */ "../../pages/login"));
    const TareaCreate = lazy(() => import(/* webpackChunkName: "examen" */ "../../pages/login"));
    const TareaList = lazy(() => import(/* webpackChunkName: "examen" */ "../../pages/login"));
    const ConsultarDocenteLista = lazy(() => import(/* webpackChunkName: "consultas" */ "../../pages/login"));
    const ConsultarDocenteView = lazy(() => import(/* webpackChunkName: "consultas" */ "../../pages/login"));
    const ConsultarDocenteSede = lazy(() => import(/* webpackChunkName: "consultas" */ "../../pages/login"));




// ARCHIVOS PARA RUTAS DE ACUDIENTES
const ConsultarDocente = lazy(() => import(/* webpackChunkName: "consultas" */ "../../pages/login"));



// ARCHIVOS PARA RUTAS DE INSTITUCIONES
const TeachersView = lazy(() => import(/* webpackChunkName: "teacehrs" */ "../../pages/login"));
const TeachersCreate = lazy(() => import(/* webpackChunkName: "teacehrs" */ "../../pages/login"));
const TeachersList = lazy(() => import(/* webpackChunkName: "teacehrs" */ "../../pages/login"));


// RUTAS PARA LOS ADMIN
const AusentismoGlobal = lazy(() => import(/* webpackChunkName: "ausentismo" */ "../../pages/login"));

const RegistrarWhatsapp = lazy(() => import(/* webpackChunkName: "config" */ "../../pages/campana/misWhatsapp"));
const ConfiguracionesGenerales = lazy(() => import(/* webpackChunkName: "config" */ "../../pages/login"));

const Help = lazy(() => import(/* webpackChunkName: "help" */ "../../pages/login"));




export const MisRutas = () => {
    const userNow = tool.getUser()

    const callUsers = (component) =>{       
        if(!userNow.isLogged){
            return <Forbidden />
        }
        return component
    };
    return (
        <Suspense fallback={<Waiting />}>            
            <BrowserRouter>
                <NotFound>
                    <Route exact path={publicRoutes.INDEX} element={<Index />} />
                    <Route path={publicRoutes.LOGIN} element={<Login />} />

                    <Route path={privateRoutes.DASHBOARD_ADMON} element={(callUsers)? <Dashboard isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.DASHBOARD} element={(callUsers)? <DashboardAdmon isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.DASHBOARD_ESTUDIANTE} element={(callUsers)? <DashboardStudent isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.DASHBOARD_DOCENTE} element={(callUsers)? <DashboardTeacher  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.MENU_LIST} element={(callUsers)? <MenuList  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.MENU_ADD} element={(callUsers)? <MenuAdd  isLogged={callUsers} /> : <Forbidden /> } />

                    <Route path={privateRoutes.ASIGNACIONES_LIST} element={(callUsers)? <AsignacionesView  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.ASIGNACIONES_NEW} element={(callUsers)? <AsignacionesCreate  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.HORARIO_VIEW} element={(callUsers)? <HorariodeClases  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={publicRoutes.HORARIO_VIEWOPEN} element={(callUsers)? <HorariodeClasesOpen  isLogged={callUsers} /> : <Forbidden /> } />
                    
                    <Route path={privateRoutes.ASISTENCIA_TEACHER} element={(callUsers)? <AsistenciaTeacher  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.ASISTENCIA_TEACHERREPORTE} element={(callUsers)? <AsistenciaTeacherListar  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.ASISTENCIA_REPORTER} element={(callUsers)? <AsistenciaListar  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.ASISTENCIA_SEGUIMIENTO} element={(callUsers)? <AsistenciaSeguimiento  isLogged={callUsers} /> : <Forbidden /> } />
                    
                    <Route path={privateRoutes.AUSENTISMO_INSTITUCION} element={(callUsers)? <AusentismoGeneral  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.AUSENTISMO_GLOBAL} element={(callUsers)? <AusentismoGlobal  isLogged={callUsers} /> : <Forbidden /> } />
                    
                    <Route path={privateRoutes.CITACION_NEW} element={(callUsers)? <CitacionCreate  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.CITACION_LIST} element={(callUsers)? <CitacionList  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.CITACION_VIEW} element={(callUsers)? <CitacionView  isLogged={callUsers} /> : <Forbidden /> } />
                    
                    <Route path={privateRoutes.CALENDAR_ADD} element={(callUsers)? <CalendarCreate  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.CALENDAR_VIEW} element={(callUsers)? <CalendarView  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.CALENDAR_OPEN} element={(callUsers)? <CalendarViewOpen  isLogged={callUsers} /> : <Forbidden /> } />

                    <Route path={privateRoutes.STUDENTS_ADD} element={(callUsers)? <StudentsCreate  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.STUDENTS_LIST} element={(callUsers)? <StudentsList  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.STUDENTS_VIEW} element={(callUsers)? <StudentsView  isLogged={callUsers} /> : <Forbidden /> } />

                    <Route path={privateRoutes.TEACHER_ADD} element={(callUsers)? <TeachersCreate  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.TEACHER_LIST} element={(callUsers)? <TeachersList  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.TEACHER_VIEW} element={(callUsers)? <TeachersView  isLogged={callUsers} /> : <Forbidden /> } />


                    <Route path={privateRoutes.EXCUSAS_TEACHER} element={(callUsers)? <ExcusasList  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.EXCUSAS_ADD} element={(callUsers)? <ExcusasCreate  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.EXCUSAS_LIST} element={(callUsers)? <ExcusasList  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.EXCUSAS_VIEW} element={ <ExcusasView /> } >
                        <Route path=":reference" element={<ExcusasView /> } />
                    </Route>
                    <Route path={privateRoutes.EXCUSAS_VIEWOPEN} element={ <ExcusasViewOpen /> } >
                        <Route path=":reference" element={<ExcusasViewOpen /> } />
                    </Route>

                    <Route path={privateRoutes.OBSERVADOR_TEACHER} element={(callUsers)? <ObservadorCreate  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.OBSERVADOR_ADD} element={(callUsers)? <ObservadorCreate  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.OBSERVADOR_LIST} element={(callUsers)? <ObservadorList  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.OBSERVADOR_VIEW} element={<ObservadorView />} />

                    <Route path={publicRoutes.COMUNICACION_VIEW} element={ <ComunicacionView /> } >
                        <Route path=":reference" element={<ComunicacionView /> } />
                    </Route>
                    <Route path={privateRoutes.COMUNICACION_ADD} element={(callUsers)? <ComunicacionCreate  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.COMUNICACION_LIST} element={(callUsers)? <ComunicacionList  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.COMUNICACION_VIEW} element={(callUsers)? <ComunicacionView  isLogged={callUsers} /> : <Forbidden /> } />

                    <Route path={privateRoutes.EXAMEN_ADD} element={(callUsers)? <ExamenCreate  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.EXAMEN_LIST} element={(callUsers)? <ExamenList  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.EXAMEN_VIEW} element={(callUsers)? <ExamenView  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.EXAMEN_VIEWRESOLVER} element={(callUsers)? <ExamenViewResolver  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.EXAMEN_VIEWSEDE} element={(callUsers)? <ExamenViewSede  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.EXAMEN_RATE} element={(callUsers)? <ExamenViewCalificar  isLogged={callUsers} /> : <Forbidden /> } />

                    <Route path={privateRoutes.TAREA_ADD} element={(callUsers)? <TareaCreate  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.TAREA_LIST} element={(callUsers)? <TareaList  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.TAREA_VIEW} element={(callUsers)? <TareaView  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.TAREA_VIEWRESOLVER} element={(callUsers)? <TareaViewResolver  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.TAREA_VIEWSEDE} element={(callUsers)? <TareaViewSede  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.TAREA_RATE} element={(callUsers)? <TareaViewCalificar  isLogged={callUsers} /> : <Forbidden /> } />

                    <Route path={privateRoutes.CONSULTA_DOCENTELIST} element={(callUsers)? <ConsultarDocenteLista  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.CONSULTA_DOCENTEADD} element={(callUsers)? <ConsultarDocente  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.CONSULTA_DOCENTEVIEW} element={(callUsers)? <ConsultarDocenteView  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.CONSULTA_DOCENTESEDE} element={(callUsers)? <ConsultarDocenteSede  isLogged={callUsers} /> : <Forbidden /> } />

                    <Route path={privateRoutes.CONFIG_WP} element={(callUsers)? <RegistrarWhatsapp  isLogged={callUsers} /> : <Forbidden /> } />
                    <Route path={privateRoutes.CONFIG_GENERAL} element={(callUsers)? <ConfiguracionesGenerales  isLogged={callUsers} /> : <Forbidden /> } />

                    <Route path={privateRoutes.HELP_GENERAL} element={(callUsers)? <Help  isLogged={callUsers} /> : <Forbidden /> } />

                </NotFound>                
            </BrowserRouter>
        </Suspense>
    )
}
